@font-face {
  font-family: FjallaOne;
  src: url('./fonts/FjallaOne-Regular.ttf') format("truetype");
}
html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  background-color: #111;
  font-family: FjallaOne;
  font-display: swap;
  transition: background-color 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow-x: hidden;
}

$column-width: 100vw / 12;

.column {
  width: $column-width;

  $padLeft: 1 2 3 4 5 6 7 8 9 10 11;
  @each $col in $padLeft {
    &-left-#{$col} {
      margin-left: $col * $column-width;
    }
  }

  $colWidths: 1 2 3 4 5 6 7 8 9 10 11;
  @each $col in $colWidths {
    &-width-#{$col} {
      width: $col * $column-width;
    }
  }
}

.gridlines {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -2;

  span {
    position: absolute;
    width: 1px;
    height: 100vh;
    background-color: #333;

    $lines: 1 2 3 4 5 6 7 8 9 10 11;
    @each $l in $lines {
      &:nth-child(#{$l}) {
        left: $l * $column-width;
      }
    }
  }
}

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transform: perspective(0);
  transform-origin: left;
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 20;

  @media screen and (max-width: 770px) {
    height: 95vh;
  }

  @keyframes titleAnimateIn {
    0% {
      transform: translateX(-10rem);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
      color: #fff;
    }
  }

  .overlay {
    transform-origin: left;
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  h1, h2, h3, h4 {
    text-transform: uppercase;
    margin: 0;
    overflow: hidden;
  }

  h1, h2, h3, h4 {
    span {
      display: inline-block;
      color: transparent;
      animation: 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0.7s forwards titleAnimateIn;

      $index: 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22;
  
      @each $i in $index {
        &:nth-child(#{$i}) {
          animation-delay: $i * 0.03 + 0.7 + s;
        }
      }
    }
  }

  h1, h4 {
    font-size: 7.5vw;
    line-height: 1;
    letter-spacing: 1vw;
    line-height: 1.1;

    @media screen and (max-width: 770px) {
      font-size: 12vw;
    }
  }

  h2 {
    font-size: 2.86vw;
    letter-spacing: 0.79vw;
    line-height: 1.6;

    @media screen and (max-width: 770px) {
      font-size: 6vw;
    }

  }

  h3 {
    font-size: 3vw;
    letter-spacing: 1.23vw;
  }
}


header {
  height: 100vh;

  .button-group {
    overflow: hidden;
  }
}

a.scroll-down-arrow {
  display: flex;
  position: relative;
  width: 13rem;
  opacity: 0;
  text-decoration: none;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  overflow: hidden;
  animation: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s forwards fadeInRight;
  
  span {
    justify-content: center;

    &.slide-in {
      position: absolute;
      top: -3rem;
      img {
        position: relative;
        top: 0.5rem;
      }
    }
    transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    span {
      transform: translateY(3rem);
    }
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(calc(100vw/12 + -0.5rem));
  }
  100% {
    transform: translateX(calc(100vw / -12));
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(100% - 3rem));
  }
}

#header[data-in-view^=true] ~ nav .header,
#vapordrive[data-in-view^=true] ~ nav .vapordrive,
#referencer[data-in-view^=true] ~ nav .referencer,
#caesarstone-vr-kitchen[data-in-view^=true] ~ nav .caesarstone-vr-kitchen,
#jennair-showroom[data-in-view^=true] ~ nav .jennair-showroom,
#one-per-person[data-in-view^=true] ~ nav .one-per-person,
#four-player-chess[data-in-view^=true] ~ nav .four-player-chess,
#pom-pons-adventure[data-in-view^=true] ~ nav .pom-pons-adventure,
#contact[data-in-view^=true] ~ nav .contact {
  span {
    transform: translateX(1rem);
    @media screen and (min-width: 771px) {
      transform: perspective(900px) translateX(2rem) translateZ(12rem);
    }
  }
}

nav {
  position: fixed;
  top: 10vh;
  left: 0;
  height: 80vh;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // transform: translateX(0);
  transform: perspective(900px) translate3d(-20rem,0rem,20rem) rotate3d(0, 1, 0, 33deg) !important;
  transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 50;

  @media screen and (max-width: 770px) {
    top: 5vh;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    left: 0;
    text-transform: uppercase;
    animation: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s forwards;
    transition: 
      transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1),
      background-color 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

    $nav-items: 1 2 3 4 5 6 7 8 9 10 11;
    @each $i in $nav-items {
      &:nth-child(#{$i}) {
        animation-delay: $i * 0.04s
      }
    }

    a {
      display: flex;
      flex: 1;
      color: #fff;
      font-size: 1.7rem;
      text-decoration: none;

      @media screen and (max-width: 750px) {
        font-size: 1.3rem;
      }

      span {
        align-self: center;
        transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);backface-visibility: hidden;
      }

      &:hover {
        span {
          transform: perspective(900px) translateX(0.5rem);
        }
      }
    }
  }
}

.nav-toggle {
  position: fixed;
  left: $column-width;
  top: 2rem;
  padding: 0;
  width: 4rem;
  height: 4rem;
  background-color: transparent;
  border: 0;
  outline: 0;
  overflow: hidden;
  z-index: 50;
  transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    cursor: pointer;

    &:before {
      transform: translate(-50%, -0.8rem);
    }
    &:after {
      transform: translate(-50%, 0.8rem);
    }
  }

  &:before, &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 3px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: -5rem;
    box-shadow: 0 0 5px #000000c7;
    transform: translate(-50%, -50%);
    transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  @keyframes navTopSlideRight {
    0% {
      left: -5rem;
    }
    100% {
      left: 50%;
    }
  }
  @keyframes navBottomSlideRight {
    0% {
      left: -5rem;
    }
    100% {
      left: 50%;
    }
  }

  &:before {
    transform: translate(-50%, -0.6rem);
    animation: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s forwards navTopSlideRight;
  }
  &:after {
    transform: translate(-50%, 0.6rem);
    animation: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.6s forwards navBottomSlideRight;
  }

  @media screen and (max-width: 770px) {
    top: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
  }
}

.nav-open {

  @media screen and (max-width: 770px) {

    header {
      transform: translateX(18rem) !important;

      .overlay {
        transform: translateX(0);
      }
    }
    .card-block {
      transform: translateX(18rem) !important;

      .overlay {
        transform: translateX(-2rem);
      }
    }
  }

  @media screen and (min-width: 771px) {
    header .overlay {
      transform: perspective(1600px) translateX(calc(13vw + 11rem)) rotate3d(0.2, 1.3, 0, -50deg) !important;
    }
    .card-block {
      .overlay {
        transform: perspective(1600px) translateX(calc(13vw + 10rem)) rotate3d(0.2, 1.3, 0, -50deg) !important;
      }
      video, picture img, .fader {
        transform: perspective(900px) translateX(calc(10vw + 13rem)) rotate3d(0, 1, 0, -30deg) !important;
      }
      .video-container {
        transform: perspective(900px) translateX(calc(2vw + 12rem)) rotate3d(0, 1, 0, -30deg) !important;
      }
    }
  }

  nav {
    transform: perspective(900px) translate3d(2rem,0rem,0rem) rotate3d(0, 1, 0, 25deg) !important;
    @media screen and (max-width: 770px) {
      transform: translateX(2rem) !important;
    }
  }

  .nav-toggle {
    transform: perspective(476px) translate(calc(13vw + 9rem), 3.5rem) rotate3d(-0.6, 2, 0.1, -84deg);
    
    @media screen and (max-width: 770px) {
      transform: translateX(18rem);
    }

    &:before, &:after {
      box-shadow: none;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

section {

  &.card-block {
    opacity: 0;
    display: block;
    position: relative;
    height: 100vh;
    padding-top: 10vh;
    padding-bottom: 10vh;
    box-sizing: border-box;
    transition: 
      transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1),
      opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 10;

    &#contact {
      @media screen and (max-width: 770px) {
        height: 50vh;
        * {
          opacity: 1 !important;
          animation: none;
          transform: none !important;
          top: 10rem;
        }
      }
      @media screen and (min-width: 771px) {
        height: 60vh;
      }
    }

    &[data-in-view=true]:nth-of-type(1) {
      transition: opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    @media screen and (max-width: 770px) {
      transform: perspective(0);
    }
    

    .overlay {
      position: fixed;
      bottom: 15vh;
      max-width: calc(100vw / 12 * 10);
      transform-origin: left;
      transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      z-index: 20;

      @media screen and (max-width: 770px) {
        position: absolute;
        height: 70vh;
        top: 50vh;
      }

      @keyframes slideUp {
        0% {
          transform: matrix(1, 0.5, 0, 1.4, 0, 30);
          opacity: 0;
        }
        100% {
          transform: matrix(1, 0, 0, 1, 0, 0);
          opacity: 1;
        }
      }

      @keyframes slideDown {
        0% {
          transform: matrix(1, -0.5, 0, 1.4, 0, -30);
          opacity: 0;
        }
        100% {
          transform: matrix(1, 0, 0, 1, 0, 0);
          opacity: 1;
        }
      }

      @keyframes fadeOut {
        0% {
          transform: matrix(1, 0, 0, 1, 0, 0);
          opacity: 1;
        }
        100% {
          transform: matrix(1, 0, 0, 1, 0, 0);
          opacity: 0;
        }
      }

      @keyframes fadeInUp {
        0% {
          opacity: 0;
          transform: translateY(1rem);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }

      @keyframes fadeInDown {
        0% {
          opacity: 0;
          transform: translateY(-1rem);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }

      @keyframes fadeInRight {
        0% {
          opacity: 0;
          transform: translateX(-10rem);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }

      h3 span, h4, p, a {
        color: #fff;
        transform-origin: left;
        opacity: 0;
      }

      h3 {
        margin: 0;
        font-size: 6vw;
        text-shadow: 0 0 5px #000;
        line-height: 1.2;
        letter-spacing: 2px;

        @media screen and (max-width: 770px) {
          font-size: 7.5vw;
          line-height: 13vw;
        }

        span {
          display: inline-block;
          transform: matrix(1, 0.5, 0, 1, 0, 30);
          text-transform: uppercase;

          $letters-list: 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25;
          @each $letters in $letters-list {
            &:nth-child(#{$letters}) {
              animation-delay: $letters * 0.015s !important;
            }
          }
        }
      }
      h4 {
        font-family: arial;
        font-size: 1.2rem;
        margin: 0;
        text-shadow: 0 0 5px #000;

        @media screen and (max-width: 770px) {
          line-height: 1.3 !important;
        }
      }
      p {
        font-family: arial;
        font-size: 1rem;
        line-height: 1.4;
        margin: 1rem 0;
        max-width: 35rem;
        text-shadow: 0 0 5px #000;
        transition: height 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      flex-direction: column;
    }

    &[data-in-view=false] {
      opacity: 0;

      .overlay {
        h3 span, h4, p, a {
          animation: 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s forwards fadeOut;
        }
      }

      @media screen and (max-width: 770px) {
        opacity: 1;
      }
    }

    &[data-in-view|=true] {
      opacity: 1;
      z-index: 30;
      video, picture img {
        opacity: 1;
        // @media screen and (max-width: 770px) {
        //   transform: none !important;
        // }
      }
    }

    &[data-in-view=true-down] {
      .overlay {
        h3 span {
          transform-origin: top left;
          animation: 0.6s cubic-bezier(0.04, 0.51, 0.44, 1) 0s forwards slideUp;
        }
        h4 {
          animation: 0.8s cubic-bezier(0.04, 0.51, 0.44, 1) 0.3s forwards fadeInUp;
        }
        p {
          animation: 0.8s cubic-bezier(0.04, 0.51, 0.44, 1) 0.4s forwards fadeInUp;
        }
        a {
          animation: 0.8s cubic-bezier(0.04, 0.51, 0.44, 1) 0.6s forwards fadeInUp;
        }
      }
    }

    &[data-in-view=true-up] {
      .overlay {
        h3 span {
          transform-origin: bottom left;
          animation: 0.6s cubic-bezier(0.04, 0.51, 0.44, 1) 0s forwards slideDown;
        }
        h4 {
          animation: 0.6s cubic-bezier(0.04, 0.51, 0.44, 1) 0.3s forwards fadeInDown;
        }
        p {
          animation: 0.6s cubic-bezier(0.04, 0.51, 0.44, 1) 0.4s forwards fadeInDown;
        }
        a {
          animation: 0.6s cubic-bezier(0.04, 0.51, 0.44, 1) 0.6s forwards fadeInDown;
        }
      }
    }

    video, picture img, .fader {
      opacity: 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 105vh;
      object-fit: cover;
      z-index: -1;
      transform-origin: center;
      transition: 
        opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1),
        transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

      @media screen and (max-width: 770px) {
        position: absolute;
        width: 100vw;
        height: 50vh;
        margin-left: calc(-100vw / 12);
        opacity: 1;
        transform: none !important;
      }
    }

    .fader {
      z-index: 0;
      background-color: #000;

      @media screen and (max-width: 770px) {
        z-index: 1;
        height: 51vh;
        opacity: 1 !important;
        transform: none !important;
        background: rgb(17,17,17);
        background: -moz-linear-gradient(0deg, rgba(17,17,17,1) 1%, rgba(17,17,17,0.09520903087797616) 19%, rgba(17,17,17,0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(17,17,17,1) 1%, rgba(17,17,17,0.09520903087797616) 19%, rgba(17,17,17,0) 100%);
        background: linear-gradient(0deg, rgba(17,17,17,1) 1%, rgba(17,17,17,0.09520903087797616) 19%, rgba(17,17,17,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#111111",endColorstr="#111111",GradientType=1);
      }
    }

    .video-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      padding: 4rem;
      justify-content: center;
      box-sizing: border-box;
      z-index: 100;
      display: none;
      flex-direction: column;
      background-color: transparent;
      transform-origin: center;
      transition: 
        background-color 0.3s cubic-bezier(0.075, 0.82, 0.165, 1),
        transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

      @media screen and (max-width: 770px) {
        padding: 5rem 0 0;
        left: -$column-width
      }

      @keyframes fadeIn {
        0% {
          background-color: transparent;
        }
        100% {
          background-color: rgba(0, 0, 0, 0.863);
        }
      }

      &.active {
        display: flex;
        animation: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1) 0s forwards fadeIn;
      }

      &.video-vertical {
        iframe {
          max-width: 45vh;
          
          @media screen and (max-width: 770px) {
            max-width: 100%;
          }
        } 
      }

      @keyframes openVideo {
        0% {
          width: 0;
          height: 0.2rem;
          background-color: #fff;
        }
        50% {
          width: 100%;
          height: 0.2rem;
          background-color: #fff;
        }
        100% {
          width: 100%;
          height: 100%;
          background-color: #000;
        }
      }

      iframe {
        position: relative;
        top: 5vh;
        border: 0;
        width: 0;
        height: 0;
        align-self: center;
        background-color: #fff;
        animation: 1s cubic-bezier(0.59, 0.03, 0.4, 0.98) 0s forwards openVideo;

        @media screen and (max-width: 770px) {
          top: 0;
        }
      }

      @keyframes slideDownButton {
        0% {
          transform: translate(-50%, -2rem);
        }
        100% {
          transform: translate(-50%, 1rem);
        }
      }

      button.close-video {
        position: absolute;
        top: 2rem;
        left: 50%;
        transform: translate(-50%, 1rem);
        border: 0;
        outline: 0;
        background-color: transparent;
        color: #d5d5d5;
        font-weight: bold;
        animation: 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s forwards slideDownButton;

        @media screen and (max-width: 770px) {
          top: 1rem;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.button-group {
  margin-top: 2rem;
  a {
    margin-top: 0.75rem;

    &:first-child {
      margin-right: 1rem;
    }
  }
}

.button {
  position: relative;
  background-color: transparent;
  min-width: 5rem;
  padding: 0.7rem 2rem;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  text-align: center;
  border: 1px solid transparent;
  font-family: arial;
  font-weight: bold;

  &-primary {

    span {
      &.text {
        position: relative;
        top: 0;
        transition: top 0.3s cubic-bezier(0.1, 0.58, 0.38, 2.21);
        z-index: 10;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 0;
      height: 2px;
      background-color: #fff;
      transition: width 0.3s cubic-bezier(0.01, 1.32, 0.89, 1.02);
      z-index: 10;
    }
    
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #000000d4;
      width: 100%;
      height: 100%;
      z-index: -1;
      transition: height 0.2s cubic-bezier(0.1, 0.58, 0.38, 2.21);
    }

    &:hover {
      span.text {
        top: -0.3rem;
      }
      &:before {
        width: 100%;
      }
      &:after {
        height: 120%;
        background-color: #000000b0;
      }
    }
  }
}

#contact {
  .button-group {
    a {
      display: inline-block;
      margin-right: 5vw;

      @media screen and (max-width: 770px) {
        margin-right: 10vw;
      }

      svg {
        width: 5vw;
        height: 5vw;
        
        path {
          transition: fill 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        @media screen and (max-width: 770px) {
          width: 12vw;
          height: 12vw;
        }
      }
      
      &.twitter:hover svg path {
        fill: #1da1f2;
      }
      &.linkedin:hover svg path {
        fill: #0a66c2;
      }
      &.email:hover svg path {
        fill: #d14138;
      }
    }
  }
}